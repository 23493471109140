import data from "./data";
import "./experience.css"
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, Pagination} from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Experience from "./Experience";

const Experiences = () => {
    return (
        <section id = "experience">
            <h2>Professional Experience</h2>
            <p></p>
            <div className="container">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: true,
                    }}
                    className="mySwiper"
                >
                {
                    data.map(i => (
                        <SwiperSlide key={i.id}>
                            <Experience experience={i}/>
                        </SwiperSlide>
                    ))
                }
                </Swiper>
            </div>
        </section>
    )
}

export default Experiences