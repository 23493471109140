import Card from "../../components/Card";

import "./experience.css"

const Experience = ({experience}) => {
    return (
        <Card key={experience.id} className="experience">
            <div className="experience__header">
                <h2>{experience.title}</h2>
                <h3>{experience.company}</h3>
                <p><i><b>{experience.dates}</b></i></p>
            </div>

            <ul className="experience__list">
                {
                    experience.duties.map(duties => (
                        <li id={duties.id}><p><b>{duties.value}</b></p></li>
                    ))
                }
            </ul>
        </Card>
    );
};

export default Experience;